import { defineStore } from 'pinia'
import type { RichTextField } from '@prismicio/client'
interface ToastMessageState {
  textMessage: RichTextField | string | null
  alertType: AlertType | null
}

export const useToastMessageStore = defineStore('toastMessage', {
  state: (): ToastMessageState => ({
    textMessage: null,
    alertType: null,
  }),
  actions: {
    setToastMessage(textMessage: RichTextField | string, alertType: AlertType) {
      this.textMessage = textMessage
      this.alertType = alertType
    },
    resetToastMessage() {
      this.textMessage = null
      this.alertType = null
    },
  },
})
