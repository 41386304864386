<script lang="ts" setup>
type CheckboxColor = 'green' | 'yellow'

export interface UiSelectListProps {
  modelValue: boolean
  isDisabled?: boolean
  label: string
  name: string
  checkboxColor?: CheckboxColor
}

withDefaults(defineProps<UiSelectListProps>(), {
  modelValue: false,
  isDisabled: false,
  label: '',
  checkboxColor: 'yellow',
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.checked)
}

const slots = useSlots()
const isSlotInUse = (slotName: string) => {
  return !!slots[slotName]
}
</script>

<template>
  <div
    class="ui-checkbox"
    :class="[
      `ui-checkbox--${checkboxColor}`,
      isDisabled ? `ui-checkbox--is-disabled` : `ui-checkbox--is-enabled`,
    ]"
  >
    <input
      :id="getChecksumFromAnyInput(label)"
      type="checkbox"
      :checked="modelValue"
      :name="name"
      :disabled="isDisabled"
      @change="updateValue"
    />
    <label v-if="isSlotInUse('default') || label !== ''" :for="getChecksumFromAnyInput(label)">
      <slot v-if="isSlotInUse('default')" />
      <span v-else>{{ label }}</span>
    </label>
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';

.ui-checkbox {
  label {
    .p-s();
    position: relative;
    cursor: pointer;
    .fluid(padding-left, 20, 28);
    margin-bottom: 0;

    * {
      margin-bottom: 0;
      display: inline-block;
    }

    a {
      .standard-anchor-styles();
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      line-height: 1;
    }
    &::before {
      left: 0;
      top: 0;
      .fluid(width, 16, 20);
      .fluid(height, 16, 20);
      border: 2px solid var(--grey-1);

      .ui-checkbox--yellow& {
        border-color: var(--yellow-1);
      }
      .ui-checkbox--green& {
        border-color: var(--green-1);
      }
    }
    &::after {
      .fluid(left, 4, 5);
      .fluid(top, 2, 3);
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:disabled + label {
      cursor: not-allowed;
      color: var(--grey-3);
      &::after {
        color: var(--grey-1);
      }
    }

    &:checked + label {
      &::after {
        content: '✓';

        .ui-checkbox--is-disabled& {
          content: '' !important;
        }
      }
      &::before {
        .ui-checkbox--yellow& {
          background-color: var(--yellow-1);
        }
        .ui-checkbox--green& {
          background-color: var(--green-1);
        }
        .ui-checkbox--is-disabled& {
          background-color: var(--grey-4) !important;
        }
      }
    }
  }
}
</style>
